import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SpinnerModule } from '@ui/components/spinner/spinner.module';

import { ButtonComponent } from './button.component';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, MatButtonModule, SpinnerModule], // IconModule
  exports: [ButtonComponent],
})
export class ButtonModule {}
