<button
  [type]="type"
  mat-button
  (click)="onClick.emit($event)"
  [ngClass]="classes"
  [color]="color"
  [disabled]="disabled || isLoading"
  [attr.aria-label]="ariaLabel">
  <!-- CONTENT -->
  <span [class.hidden]="isLoading">
    <ng-content></ng-content>
  </span>
  <!-- SPINNER -->
  <ui-spinner
    *ngIf="isLoading"
    class="button__spinner"
    color="gray"
    size="small"></ui-spinner>
</button>
