import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  /**
   * Button's label
   */
  @Input()
  ariaLabel: string;

  /**
   * Button color
   * By default 'primary'
   */
  @Input()
  color: 'primary' | 'warn' | 'dark-blue' | 'none' = 'primary';

  /**
   * Button type
   * By default 'button'
   */
  @Input()
  type: 'button' | 'submit' = 'button';

  /**
   * Button style given Angular Material variants
   * By default 'raised'
   */
  @Input()
  mode: 'raised' | 'flat' | 'icon' | 'stroked' = 'flat';

  /**
   * Button size
   * By default 'medium'
   */
  @Input()
  size:
    | 'none'
    | 'x-small'
    | 'small'
    | 'medium'
    | 'large'
    | 'x-large'
    | 'dialog'
    | 'nav' = 'large';

  /**
   * Button text casting
   * By default 'uppercase'
   */
  @Input()
  casting: 'uppercase' | 'none' = 'uppercase';

  /**
   * Is button loading ?
   */
  @Input()
  isLoading: boolean;

  /**
   * Is button disabled ?
   */
  @Input()
  disabled: boolean;

  /**
   * Is button CALL TO ACTION ?
   */
  @Input()
  isCTA: boolean;

  /**
   * Is a link ?
   */
  @Input()
  appearance?: 'link';

  /**
   * Optional click handler
   */
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onClick = new EventEmitter<Event>();

  constructor() {}

  public get classes(): string[] {
    return [
      `mat-${this.mode}-button`,
      'button',
      `button--${this.color}`,
      `button--${this.size}`,
      `button--${this.casting}`,
      this.isCTA ? 'button--cta' : '',
      this.appearance ? `button--${this.appearance}` : '',
      this.disabled || this.isLoading ? 'button--disabled' : '', // Priority
    ];
  }
}
